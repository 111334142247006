import { Box, styled } from '@mui/material';

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(90deg, #94F617 0%, #B9F86B 100%)',
}));

export default IconWrapper; 