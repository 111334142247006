import React, { useState } from 'react';
import { Box, Container, Typography, Tabs, Tab } from '@mui/material';
import Dashboard from './Dashboard';
import UserManagement from './UserManagement';
import GameManagement from './GameManagement';

// Types pour les onglets
type TabType = 'dashboard' | 'users' | 'tournaments' | 'jeux';

const Admin = () => {
  const [activeTab, setActiveTab] = useState<TabType>('dashboard');

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <Dashboard />;
      case 'users':
        return <UserManagement />;
      case 'tournaments':
        return <div>Gestion des tournois</div>;
      case 'jeux':
        return <GameManagement />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <Box sx={{ bgcolor: '#202020', minHeight: '100vh' }}>
      <Container maxWidth="xl">
        <Box sx={{ mb: 6 }}>
          <Typography variant="h4" fontWeight="bold" sx={{ mb: 0.25 }}>
            Administration
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Gérer les utilisateurs et les tournois
          </Typography>
        </Box>
        <Box sx={{ bgcolor: '#252525', p: 3, borderRadius: 2 }}>
          <Tabs 
            value={activeTab} 
            onChange={(event, newValue) => setActiveTab(newValue as TabType)}
          >
            <Tab label="Tableau de bord" value="dashboard" />
            <Tab label="Utilisateurs" value="users" />
            <Tab label="Tournois" value="tournaments" />
            <Tab label="Jeux" value="jeux" />
          </Tabs>
          <Box sx={{ mt: 3 }}>
            {renderContent()}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Admin;
