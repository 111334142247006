import React from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../config/api';
import VoiceChat from './VoiceChat';

const Chat = () => {
  const { user } = useAuth();

  const handleTeamSpeakConnect = async () => {
    const nickname = encodeURIComponent(user?.username || 'Guest');
    try {
      // Try to launch TeamSpeak using the default installation path
      const command = `start "" "C:\\Program Files\\TeamSpeak 3 Client\\ts3client_win64.exe" "ts3server://127.0.0.1?port=9987&nickname=${nickname}&channel=ToursArena"`;
      
      // Send command to backend to execute using the configured axios instance
      const response = await api.post('/auth/launch-teamspeak', { command });

      if (!response.data) {
        throw new Error('Failed to launch TeamSpeak');
      }
    } catch (error) {
      console.error('Error launching TeamSpeak:', error);
      // Fallback to protocol URL if direct launch fails
      window.location.href = `ts3server://127.0.0.1?port=9987&nickname=${nickname}&channel=ToursArena`;
    }
  };

  return (
    <Box sx={{ bgcolor: '#202020', minHeight: '100vh' }}>
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" fontWeight="bold" sx={{ mb: 0.25 }}>
            Chat
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            Discutez avec la communauté
          </Typography>
          
          {/* Voice Chat Rooms */}
          <VoiceChat />
          
          <Divider sx={{ my: 4 }} />
          
          {/* TeamSpeak Button */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Alternative Voice Chat
            </Typography>
            <a
              onClick={handleTeamSpeakConnect}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                padding: '12px 24px',
                backgroundColor: 'var(--color-secondary)',
                color: 'white',
                borderRadius: '8px',
                textDecoration: 'none',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.2s ease-in-out',
                cursor: 'pointer',
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = 'var(--color-hover)';
                e.currentTarget.style.transform = 'translateY(-2px)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'var(--color-secondary)';
                e.currentTarget.style.transform = 'translateY(0)';
              }}
            >
              <HeadsetMicIcon style={{ marginRight: '8px' }} />
              Rejoindre TeamSpeak
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Chat;
