import React from 'react';
import { Card, CardContent, CardActions, Typography, Button, Box, Chip, Stack } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import BlockIcon from '@mui/icons-material/Block';
import GroupIcon from '@mui/icons-material/Group';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import { TournamentCardProps } from './types';
import { Countdown } from './Countdown';

const getGameImage = (game: any): string => {
  if (!game || !game.image) {
    return '/images/games/default.jpg';
  }
  return `data:image/jpeg;base64,${game.image}`;
};

const translateTournamentType = (type: string) => {
  const translations: { [key: string]: string } = {
    'Double Elimination': 'Double élimination',
    'Single Elimination': 'Simple élimination'
  };
  return translations[type] || type;
};

export const TournamentCard: React.FC<TournamentCardProps> = ({ tournament, onJoin, onLeave, onDelete, isAuthenticated, userId }) => {
  const isOrganizer = tournament.organizer?.id === userId;
  const isParticipant = tournament.participants?.some(p => p.id === userId) || false;
  
  const canJoinTournament = () => {
    return tournament.status === 'open' &&
           !isParticipant &&
           (tournament.participants?.length || 0) < tournament.maxParticipants;
  };

  const formatTournamentMode = (format: string) => {
    return format
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatDateTime = (date: string) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const formattedDate = `${day}/${month}/${d.getFullYear()}`;
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');
    return `${formattedDate} - ${hours}:${minutes}`;
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', transition: 'all 0.3s ease', borderRadius: '16px', overflow: 'hidden', background: '#252525', border: '1px solid #333', boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 12px 48px rgba(0, 0, 0, 0.3)', borderColor: '#444', }, }}>
      <Box sx={{ position: 'relative', height: '200px' }}>
        <Box 
          sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${getGameImage(tournament.Game)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.8) 80%)',
              zIndex: 1
            }
          }} 
        />
        <Typography variant="h5" sx={{ position: 'absolute', bottom: 16, left: 16, right: 16, fontWeight: 600, color: '#fff', textShadow: '0 2px 4px rgba(0,0,0,0.4)', fontSize: '1.5rem', lineHeight: 1.2, zIndex: 2, }}>
          {tournament.Game?.title || tournament.name}
        </Typography>
        {isParticipant && (
          <Box sx={{ position: 'absolute', top: 20, right: 20, animation: 'fadeScale 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)', '@keyframes fadeScale': { '0%': { transform: 'scale(0)', opacity: 0 }, '100%': { transform: 'scale(1)', opacity: 1 } } }}>
            <Box sx={{ position: 'relative', padding: '10px 20px', background: 'rgba(13, 17, 23, 0.8)', borderRadius: '12px', backdropFilter: 'blur(8px)', border: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'hidden', '&::before': { content: '""', position: 'absolute', top: '-50%', left: '-50%', width: '200%', height: '200%', background: 'conic-gradient(from 0deg, transparent 0deg, rgba(255, 255, 255, 0.3) 90deg, transparent 180deg)', animation: 'rotate 4s linear infinite', zIndex: 0 }, '&::after': { content: '""', position: 'absolute', inset: '1px', borderRadius: '11px', background: 'rgba(13, 17, 23, 0.9)', zIndex: 1 }, '@keyframes rotate': { '0%': { transform: 'rotate(0deg)' }, '100%': { transform: 'rotate(360deg)' } } }}>
              <Box sx={{ position: 'relative', zIndex: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Box sx={{ width: '8px', height: '8px', background: 'linear-gradient(45deg, #4CAF50, #81C784)', borderRadius: '50%', animation: 'pulse 1.5s ease-in-out infinite', '@keyframes pulse': { '0%, 100%': { transform: 'scale(1)', boxShadow: '0 0 0 0 rgba(76, 175, 80, 0.7)' }, '50%': { transform: 'scale(1.2)', boxShadow: '0 0 0 10px rgba(76, 175, 80, 0)' } } }} />
                <Typography sx={{ color: '#fff', fontWeight: 600, fontSize: '13px', textTransform: 'uppercase', letterSpacing: '0.1em', textShadow: '0 0 15px rgba(255, 107, 43, 0.3)', marginLeft: '12px' }}>Inscrit</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <CardContent sx={{ flexGrow: 1, p: 3, position: 'relative', zIndex: 1 }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box sx={{ flex: 1, mb: -1}}>
            <Typography variant="h5" sx={{ color: '#fff', mb: 2, fontWeight: 600 }}>
              {tournament.name}
            </Typography>
            <Typography variant="h6" sx={{ color: '#fff', mb: 1, fontWeight: 600 }}>{translateTournamentType(formatTournamentMode(tournament.format))}</Typography>
            <Typography variant="body2" sx={{ color: '#aaa', mb: 6 }}>{tournament.description || 'Aucune description'}</Typography>
            <Countdown startDate={tournament.startDate} />
            <Stack spacing={2.5}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <GroupIcon sx={{ color: '#aaa', fontSize: 20 }} />
                  <Typography variant="body2" sx={{ color: '#fff' }}>{tournament.participants?.length || 0} / {tournament.maxParticipants}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarTodayIcon sx={{ color: '#aaa', fontSize: 20 }} />
                  <Typography variant="body2" sx={{ color: '#fff' }}>{formatDateTime(tournament.startDate)}</Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
          <Chip label={tournament.status.replace('_', ' ').toUpperCase()} sx={{ ml: -7, background: tournament.status === 'open' ? 'rgba(46, 196, 182, 0.15)' : tournament.status === 'in_progress' ? 'rgba(255, 159, 67, 0.15)' : 'rgba(231, 76, 60, 0.15)', color: tournament.status === 'open' ? '#2ec4b6' : tournament.status === 'in_progress' ? '#ff9f43' : '#e74c3c', backdropFilter: 'blur(8px)', border: '1px solid', borderColor: tournament.status === 'open' ? 'rgba(46, 196, 182, 0.3)' : tournament.status === 'in_progress' ? 'rgba(255, 159, 67, 0.3)' : 'rgba(231, 76, 60, 0.3)', fontWeight: 600, fontSize: '0.75rem', height: '24px', borderRadius: '12px', letterSpacing: '0.5px', textTransform: 'capitalize', transition: 'all 0.2s ease-in-out', '&:hover': { transform: 'translateY(-1px)', boxShadow: tournament.status === 'open' ? '0 2px 8px rgba(46, 196, 182, 0.2)' : tournament.status === 'in_progress' ? '0 2px 8px rgba(255, 159, 67, 0.2)' : '0 2px 8px rgba(231, 76, 60, 0.2)' } }} />
        </Box>
      </CardContent>
      <CardActions sx={{ p: 2, pt: 0, gap: 1 }}>
        <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
          {isAuthenticated && (
            <>
              {canJoinTournament() ? (
                <Button startIcon={<PersonAddIcon />} onClick={(e) => onJoin(tournament.id, e)} variant="contained" fullWidth sx={{ bgcolor: 'rgba(80, 200, 120, 0.15)', color: '#98f1b1', borderRadius: '8px', textTransform: 'none', fontWeight: 500, '&:hover': { bgcolor: 'rgba(80, 200, 120, 0.25)', }, '& .MuiSvgIcon-root': { color: '#98f1b1', } }}>{tournament.status === 'open' && isOrganizer ? 'Inscription' : 'Inscription au tournoi'}</Button>
              ) : isParticipant && tournament.status === 'open' ? (
                <Button onClick={(e) => onLeave(tournament.id, e)} variant="contained" fullWidth startIcon={<LogoutIcon />} sx={{ bgcolor: 'rgba(147, 87, 229, 0.15)', color: '#c29ffa', borderRadius: '8px', textTransform: 'none', fontWeight: 500, '&:hover': { bgcolor: 'rgba(147, 87, 229, 0.25)', }, '& .MuiSvgIcon-root': { color: '#c29ffa', } }}>{tournament.status === 'open' && isOrganizer ? 'Désinscrire' : 'Leave tournament'}</Button>
              ) : tournament.status === 'in_progress' ? (
                <Button startIcon={<BlockIcon />} variant="contained" fullWidth disabled sx={{ bgcolor: 'rgba(158, 158, 158, 0.12)', color: 'rgba(255, 255, 255, 0.3)', borderRadius: '8px', textTransform: 'none', fontWeight: 500, border: '1px solid rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(4px)', cursor: 'not-allowed', opacity: 0.7, '&.Mui-disabled': { bgcolor: 'rgba(158, 158, 158, 0.12)', color: 'rgba(255, 255, 255, 0.3)', } }}>Tournoi en cours</Button>
              ) : tournament.participants && tournament.participants.length >= tournament.maxParticipants && tournament.status === 'open' ? (
                <Button startIcon={<BlockIcon />} variant="contained" fullWidth disabled sx={{ bgcolor: 'rgba(158, 158, 158, 0.12)', color: 'rgba(255, 255, 255, 0.3)', borderRadius: '8px', textTransform: 'none', fontWeight: 500, border: '1px solid rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(4px)', cursor: 'not-allowed', opacity: 0.7, '&.Mui-disabled': { bgcolor: 'rgba(158, 158, 158, 0.12)', color: 'rgba(255, 255, 255, 0.3)', } }}>Tournoi complet</Button>
              ) : null}
              {isOrganizer && tournament.status === 'open' && (
                <Button onClick={(e) => onDelete(tournament.id, tournament.name, e)} variant="contained" fullWidth startIcon={<DeleteIcon />} sx={{ bgcolor: 'rgba(231, 76, 60, 0.15)', color: '#ff7675', borderRadius: '8px', textTransform: 'none', fontWeight: 500, '&:hover': { bgcolor: 'rgba(231, 76, 60, 0.25)', }, '& .MuiSvgIcon-root': { color: '#ff7675', } }}>Supprimer</Button>
              )}
            </>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

