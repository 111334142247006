import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

interface StatCardProps {
  title: string;
  value: string;
  description: string;
  icon: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, description, icon }) => {
  return (
    <Paper 
      elevation={2}
      sx={{
        p: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Typography variant="h6" component="h3" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" component="p" sx={{ mb: 1, fontWeight: 'bold' }}>
        {value}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          right: -10,
          top: -10,
          fontSize: '4rem',
          opacity: 0.3,
          transform: 'rotate(15deg)'
        }}
      >
        {icon}
      </Box>
    </Paper>
  );
};

export default StatCard; 