import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  styled,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

export interface TournamentGrowthData {
  name: string;
  value: number;
}

export interface TournamentGrowthCardProps {
  data: readonly TournamentGrowthData[];
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#252525',
  borderRadius: 16,
  padding: theme.spacing(3),
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: 'linear-gradient(90deg, #FF6B6B, #FF8E53)',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  fontSize: '0.875rem',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(255, 255, 255, 0.2)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(255, 255, 255, 0.3)',
  },
  '& .MuiSelect-icon': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
  }>;
  label?: string;
}

const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: '#252525',
          border: '1px solid rgba(255,255,255,0.1)',
          borderRadius: '8px',
          padding: '8px 12px',
        }}
      >
        <Typography variant="body2" color="rgba(255,255,255,0.7)">
          {label}
        </Typography>
        <Typography variant="body1" color="#94F617" fontWeight="bold">
          {payload[0].value} tournois
        </Typography>
      </Box>
    );
  }
  return null;
};

const STORAGE_KEY = 'tournamentGrowthMonths';

export const TournamentGrowthCard: React.FC<TournamentGrowthCardProps> = ({ data }) => {
  // Initialize state from localStorage or default to 12
  const [monthsToShow, setMonthsToShow] = useState<number>(() => {
    const savedMonths = localStorage.getItem(STORAGE_KEY);
    return savedMonths ? parseInt(savedMonths, 10) : 12;
  });
  
  // Save to localStorage whenever monthsToShow changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, monthsToShow.toString());
  }, [monthsToShow]);
  
  // Convert readonly array to mutable for recharts and filter by months
  const mutableData = [...data].slice(-monthsToShow);
  
  // Calculate the maximum value for YAxis domain
  const maxValue = Math.max(...mutableData.map(item => item.value));
  const yAxisMax = Math.ceil(maxValue * 1.2); // Add 20% padding
  
  return (
    <StyledPaper elevation={0}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" fontWeight="bold">
          Croissance des tournois
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FormControl size="small">
            <StyledSelect
              value={monthsToShow}
              onChange={(e) => setMonthsToShow(Number(e.target.value))}
              sx={{ minWidth: 100 }}
            >
              <MenuItem value={3}>3 mois</MenuItem>
              <MenuItem value={6}>6 mois</MenuItem>
              <MenuItem value={12}>12 mois</MenuItem>
            </StyledSelect>
          </FormControl>
          <IconButton size="small">
            <MoreVert />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ height: 200, width: '100%', mt: 2 }}>
        <ResponsiveContainer>
          <LineChart data={mutableData} margin={{ top: 10, right: 30, left: 10, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
            <XAxis
              dataKey="name"
              stroke="rgba(255,255,255,0.7)"
              tick={{ fill: 'rgba(255,255,255,0.7)', fontSize: 14 }}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              stroke="rgba(255,255,255,0.7)"
              tick={{ fill: 'rgba(255,255,255,0.7)', fontSize: 14 }}
              tickLine={false}
              axisLine={false}
              domain={[0, yAxisMax]}
              allowDecimals={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#94F617"
              strokeWidth={3}
              dot={{ fill: '#94F617', r: 6 }}
              activeDot={{ r: 8, fill: '#94F617' }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </StyledPaper>
  );
};

export default TournamentGrowthCard;
