import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  Alert,
  Input,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as api from '../../services/api';

interface FormValues {
  title: string;
  image: string;
}

const validationSchema = Yup.object({
  title: Yup.string()
    .required('Le titre est requis')
    .min(3, 'Le titre doit contenir au moins 3 caractères')
    .max(100, 'Le titre ne doit pas dépasser 100 caractères'),
  image: Yup.string()
    .required('L\'image est requise')
});

export const CreateGame = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        // Validate file type
        if (!file.type.startsWith('image/')) {
          setError('Le fichier doit être une image');
          return;
        }

        // Create preview URL
        const preview = URL.createObjectURL(file);
        setPreviewUrl(preview);

        // Compress and convert to base64
        const compressedBase64 = await compressImage(file);
        console.log('Image compressed and converted, size:', Math.round(compressedBase64.length / 1024), 'KB');
        formik.setFieldValue('image', compressedBase64);
      } catch (err) {
        console.error('Error processing image:', err);
        setError('Erreur lors du chargement de l\'image');
      }
    }
  };

  const compressImage = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Calculate new dimensions while maintaining aspect ratio
          const maxDimension = 1200;
          if (width > height && width > maxDimension) {
            height = (height * maxDimension) / width;
            width = maxDimension;
          } else if (height > maxDimension) {
            width = (width * maxDimension) / height;
            height = maxDimension;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            reject(new Error('Failed to get canvas context'));
            return;
          }

          // Draw and compress image
          ctx.drawImage(img, 0, 0, width, height);
          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.7); // 0.7 quality
          resolve(compressedBase64);
        };
        img.onerror = reject;
        img.src = e.target?.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      title: '',
      image: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setError(null);
      try {
        console.log('Submitting form with values:', {
          title: values.title,
          imageSize: values.image ? `${Math.round(values.image.length / 1024)}KB` : '0KB'
        });

        await api.createGame({
          title: values.title,
          image: values.image,
        });
        navigate('/admin');
      } catch (err: any) {
        console.error('Error creating game:', err);
        setError(
          err.response?.data?.error || 
          err.response?.data?.details || 
          err.message || 
          'Une erreur est survenue lors de la création du jeu'
        );
      }
    },
  });

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Créer un nouveau jeu
        </Typography>

        <Paper sx={{ p: 3, bgcolor: '#252525' }}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Titre du jeu"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#404040',
                    },
                    '&:hover fieldset': {
                      borderColor: '#505050',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'primary.main',
                    },
                  },
                }}
              />

              <Box>
                <Input
                  type="file"
                  id="image"
                  name="image"
                  onChange={handleImageChange}
                  sx={{ display: 'none' }}
                  inputProps={{ accept: 'image/*' }}
                />
                <label htmlFor="image">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      bgcolor: 'var(--color-secondary)',
                      color: 'white',
                      '&:hover': { bgcolor: 'var(--color-hover)' },
                      mb: 1
                    }}
                  >
                    Choisir une image
                  </Button>
                </label>
                {formik.touched.image && formik.errors.image && (
                  <Typography color="error" variant="caption" display="block">
                    {formik.errors.image}
                  </Typography>
                )}
                {previewUrl && (
                  <Box sx={{ mt: 2, maxWidth: '200px' }}>
                    <img
                      src={previewUrl}
                      alt="Preview"
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '4px'
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={formik.isSubmitting}
                  sx={{
                    bgcolor: 'var(--color-secondary)',
                    color: 'white',
                    '&:hover': { bgcolor: 'var(--color-hover)' },
                  }}
                >
                  Créer
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/admin')}
                  sx={{
                    borderColor: '#404040',
                    color: 'white',
                    '&:hover': {
                      borderColor: '#505050',
                      bgcolor: 'rgba(80, 80, 80, 0.1)',
                    },
                  }}
                >
                  Annuler
                </Button>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default CreateGame;
