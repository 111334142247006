import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="nav-logo" style={{ fontFamily: 'Inter, sans-serif', color: '#FFFFFF' }}>
          TOURS ARENA
        </Link>

        <div className="nav-toggle" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>

        <div className={`nav-links ${isOpen ? 'active' : ''}`}>
          <Link to="/login" className="nav-link login-button">Se connecter</Link>
          <Link to="/register" className="nav-button">S'inscrire</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
