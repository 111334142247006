import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import { Link as RouterLink } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import './Hero.css';

// Import des images
import gamingBg from '../../assets/images/gaming-bg.b887c8b8.png';
import esports1 from '../../assets/images/esports1.4fc43c13.jpg';
import esports2 from '../../assets/images/esports2.14ca7100.jpg';

// Images du carrousel
const carouselImages = [
  gamingBg,
  esports1,
  esports2,
];

const Hero = () => {
  return (
    <>
      <Box
        sx={{
          bgcolor: '#1A1A1A',
          color: 'white',
          pt: 2,
          pb: 6,
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '80px',
          fontFamily: 'Inter, sans-serif',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              flexWrap: { xs: 'wrap', md: 'nowrap' },
            }}
          >
            {/* Left side - Text content */}
            <Box sx={{ flex: '1', minWidth: { xs: '100%', md: '45%' } }}>
              <Typography
                component="h1"
                variant="h1"
                className="typewriter"
                sx={{
                  fontSize: { xs: '3.5rem', md: '5rem' },
                  mb: 4,
                  fontWeight: 700,
                }}
              >
                TOURS ARENA
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mb: 4,
                  color: '#9E9E9E',
                  fontSize: { xs: '1rem', md: '1.2rem' },
                  lineHeight: 1.8,
                  maxWidth: '600px'
                }}
              >
                Préparez-vous pour le plus grand tournoi LAN de Tours ! Affrontez les meilleures équipes et vivez l'intensité de la compétition en direct !
              </Typography>
              <Box
                component={RouterLink}
                to="/login"
                sx={{
                  display: 'inline-block',
                  bgcolor: '#FF6B2B',
                  color: 'white',
                  px: 4,
                  py: 2,
                  borderRadius: '30px',
                  textDecoration: 'none',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    bgcolor: '#FF8E53',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 5px 15px rgba(255, 107, 43, 0.4)',
                  },
                }}
              >
                Commencer maintenant
              </Box>
            </Box>

            {/* Right side - Card Carousel */}
            <Box
              sx={{
                flex: '1',
                minWidth: { xs: '100%', md: '45%' },
                height: '700px',
                position: 'relative',
                transform: { xs: 'none', md: 'translateX(120px)' },
                '& .swiper': {
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle at center, rgba(255,107,43,0.1) 0%, rgba(26,26,26,0) 70%)',
                    pointerEvents: 'none',
                    zIndex: 1,
                  },
                },
              }}
            >
              <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                loop={true}
                modules={[EffectCoverflow, Autoplay]}
                className="mySwiper"
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2.5,
                  slideShadows: false,
                }}
                speed={1000}
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: '50px',
                }}
              >
                {carouselImages.map((image, index) => (
                  <SwiperSlide 
                    key={index}
                    style={{
                      width: '500px',
                      height: '600px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '20px',
                        overflow: 'hidden',
                        boxShadow: '0 8px 20px rgba(0,0,0,0.3)',
                        transition: 'all 0.5s ease-out',
                        '&:hover': {
                          transform: 'scale(1.02)',
                          boxShadow: '0 12px 28px rgba(0,0,0,0.4)',
                        },
                      }}
                    >
                      <img
                        src={image}
                        alt={`Gaming event ${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          transition: 'transform 0.5s ease-out',
                        }}
                      />
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Hero;
