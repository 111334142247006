import React from 'react';
import { Box } from '@mui/material';

const Separator = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: { xs: 2, md: 3 },
        bgcolor: '#1A1A1A',
      }}
    >
      <Box
        sx={{
          width: '90%',
          maxWidth: '1200px',
          height: '1px',
          background: 'linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(42,42,42,1) 50%, rgba(26,26,26,1) 100%)',
        }}
      />
    </Box>
  );
};

export default Separator;
