import axios from 'axios';
import { Tournament, TournamentStatus } from '../types';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add token to requests if available
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Auth API
export const login = (email: string, password: string) =>
  api.post('/auth/login', { email, password });

export const register = (username: string, email: string, password: string) =>
  api.post('/auth/register', { username, email, password });

export const verifyToken = () => api.post('/auth/verify');

// Tournament API
export const getTournaments = () => api.get('/tournaments');

export const getTournament = (id: string) => api.get(`/tournaments/${id}`);

export const createTournament = (data: {
  name: string;
  format: string;
  maxParticipants: number;
  startDate: string;
  description?: string;
  gameId: string;
}) => api.post('/tournaments', data);

export const registerForTournament = (tournamentId: string) => {
  return api.post<Tournament>(`/tournaments/${tournamentId}/register`);
};

export const joinTournament = (tournamentId: string) => {
  return registerForTournament(tournamentId);
};

export const quitTournament = (tournamentId: string) => {
  return api.post<Tournament>(`/tournaments/${tournamentId}/quit`);
};

export const leaveTournament = (tournamentId: string) => {
  return api.post<Tournament>(`/tournaments/${tournamentId}/quit`);
};

export const startTournament = (id: string) =>
  api.post(`/tournaments/${id}/start`);

export const deleteTournament = (id: string) =>
  api.delete(`/tournaments/${id}`);

export const getTournamentMatches = (id: string) =>
  api.get(`/tournaments/${id}/matches`);

export const getTournamentParticipants = async (id: string) => {
  try {
    const response = await api.get(`/tournaments/${id}/participants`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tournament participants:', error);
    throw error;
  }
};

// Game API
export const getAllGames = () => api.get('/games');

export const createGame = async (data: {
  title: string;
  image: string;
}) => {
  try {
    console.log('Preparing to send game data:', {
      title: data.title,
      imageSize: data.image ? `${Math.round(data.image.length / 1024)}KB` : '0KB'
    });

    // Validate image size before sending
    if (data.image && data.image.length > 16 * 1024 * 1024) {
      throw new Error('Image size exceeds 16MB limit');
    }

    const response = await api.post('/games', data, {
      headers: {
        'Content-Type': 'application/json',
      },
      maxContentLength: 20 * 1024 * 1024, // 20MB limit
      maxBodyLength: 20 * 1024 * 1024, // 20MB limit
      timeout: 30000 // 30 second timeout
    });

    console.log('Game created successfully:', response.data.id);
    return response;
  } catch (error: any) {
    console.error('Error creating game:', {
      message: error.message,
      response: error.response?.data
    });
    throw error;
  }
};

export const updateGame = (id: string, data: {
  title?: string;
  description?: string;
  image?: string;
}) => api.put(`/games/${id}`, data);

export const deleteGame = (id: string) => api.delete(`/games/${id}`);

// Match API
export const getMatch = (id: string) =>
  api.get(`/matches/${id}`);

export const updateMatch = (matchId: string, data: { winnerId: string }) =>
  api.put(`/matches/${matchId}`, data);

export const updateMatchResult = (matchId: string, data: { winnerId: string }) =>
  api.post(`/matches/${matchId}/result`, data);

export const updateTournament = (id: string, data: {
  name?: string;
  description?: string;
  status?: TournamentStatus;
  maxParticipants?: number;
  startDate?: string;
}) => api.patch(`/tournaments/${id}`, data);

export const getActiveTournaments = () => 
  api.get<Tournament[]>('/tournaments/active');

// Stats API
export const getStats = () => api.get('/stats');

// Tournament Growth API
export const getTournamentGrowth = () => api.get('/tournaments/growth');

// Today's Tournaments API
export const getTodaysTournaments = () => api.get('/tournaments/today');

// Top Players API
export const getTopPlayers = () => api.get('/players/top');

// Games
export const getGames = () => {
  return api.get('/games');
};

export default api;
