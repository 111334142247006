import React from 'react';
import { Link } from 'react-router-dom';
import './CallToAction.css';

const CallToAction = () => {
  return (
    <section className="cta">
      <div className="cta-content">
        <h2 className="cta-title">Prêt à lancer votre tournoi ?</h2>
        <p className="cta-subtitle">Rejoignez vos amis et commencez à jouer maintenant !</p>
        <Link to="/register" className="cta-button">
          S'inscrire
        </Link>
      </div>
    </section>
  );
};

export default CallToAction;
