import React from 'react';
import './Footer.css';
import { FaFacebook, FaDiscord } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer" style={{ fontFamily: 'Inter, sans-serif' }}>
      <div className="footer-content">
        <div className="footer-section">
          <h3>Tours Arena</h3>
          <p>Application de gestion de tournois pour vos LAN parties</p>
        </div>
        <div className="footer-section">
          <h3>Liens rapides</h3>
          <ul>
            <li><a href="/dashboard">Créer un tournoi</a></li>
            <li><a href="/tournaments">Tournois en cours</a></li>
            <li><a href="/guide">Guide d'utilisation</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Communauté</h3>
          <div className="social-links">
            <a href="https://www.facebook.com/groups/116655201685563" target="_blank" rel="noopener noreferrer">
              <FaFacebook /> Facebook
            </a>
            <a href="https://discord.gg/yourdiscord" target="_blank" rel="noopener noreferrer">
              <FaDiscord /> Discord
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
