import React from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  MenuItem,
  Alert,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import * as api from '../../services/api';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Le nom du tournoi est requis'),
  format: yup
    .string()
    .required('Le format du tournoi est requis'),
  maxParticipants: yup
    .number()
    .required('Le nombre de participants maximum est requis')
    .min(2, 'Il dois y avoir au moins 2 participants')
    .max(128, 'Il dois y avoir au plus 128 participants'),
  startDate: yup
    .string()
    .required('La date de début est requise')
    .test('is-future', 'La date de début doit être dans le futur', (value) => {
      if (!value) return false;
      return dayjs(value).isAfter(dayjs());
    }),
  gameId: yup
    .string()
    .required('Le jeu est requis'),
  description: yup.string(),
});

const tournamentFormats = [
  { value: 'single-elimination', label: 'Simple élimination' },
  { value: 'double-elimination', label: 'Double élimination' },
];

const formStyles = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#808080',
    },
    '&:hover fieldset': {
      borderColor: '#A0A0A0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#A0A0A0',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#808080',
    '&.Mui-focused': {
      color: '#A0A0A0',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#fff',
    '&:focus': {
      boxShadow: 'none',
    },
  },
  '& .MuiIconButton-root': {
    color: '#fff',
  },
  '& .MuiSvgIcon-root': {
    color: '#fff',
  },
  '& .MuiPickersCalendarHeader-root': {
    color: '#fff',
  },
  '& .MuiPickersDay-root': {
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&.Mui-selected': {
      backgroundColor: 'var(--color-secondary)',
      '&:hover': {
        backgroundColor: 'var(--color-hover)',
      },
    },
  },
  '& .MuiClock-pin': {
    backgroundColor: 'var(--color-secondary)',
  },
  '& .MuiClockPointer-root': {
    backgroundColor: 'var(--color-secondary)',
  },
  '& .MuiClockPointer-thumb': {
    backgroundColor: 'var(--color-secondary)',
    borderColor: 'var(--color-secondary)',
  },
  '& .MuiButton-root': {
    color: 'var(--color-secondary)',
  },
  '& .MuiPickersDay-today': {
    borderColor: 'var(--color-secondary) !important',
  },
  '& .MuiPickersDay-daySelected': {
    backgroundColor: 'var(--color-secondary) !important',
    '&:hover': {
      backgroundColor: 'var(--color-hover) !important',
    },
  },
  '& .MuiClockNumber-root': {
    '&.Mui-selected': {
      backgroundColor: 'var(--color-secondary) !important',
    },
  },
  '& .MuiTimeClock-clock': {
    '& .Mui-selected': {
      backgroundColor: 'var(--color-secondary)',
    },
  },
  '& .MuiClock-clock': {
    '& .Mui-selected': {
      backgroundColor: 'var(--color-secondary)',
    },
  },
};

const CreateTournament = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState<string | null>(null);
  const [games, setGames] = React.useState<Array<{ id: string, title: string }>>([]);

  React.useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await api.getGames();
        setGames(response.data);
      } catch (err) {
        console.error('Failed to fetch games:', err);
        setError('Échec du chargement des jeux');
      }
    };
    fetchGames();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      format: '',
      maxParticipants: '',
      startDate: '',
      description: '',
      gameId: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await api.createTournament({
          ...values,
          maxParticipants: Number(values.maxParticipants),
        });
        navigate('/tournaments');
      } catch (err: any) {
        setError(err.response?.data?.error || 'Echec de la création du tournoi');
      }
    },
  });

  return (
    <Box 
      sx={{
        minHeight: '100vh',
        background: '#202020',
        pt: 4,
        pb: 4,
      }}
    >
      <Container maxWidth="sm">
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Créer un tournoi
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Nom du tournoi"
              margin="normal"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={formStyles}
            />

            <TextField
              fullWidth
              id="format"
              name="format"
              select
              label="Format du tournoi"
              margin="normal"
              value={formik.values.format}
              onChange={formik.handleChange}
              error={formik.touched.format && Boolean(formik.errors.format)}
              helperText={formik.touched.format && formik.errors.format}
              sx={formStyles}
            >
              {tournamentFormats.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              id="gameId"
              name="gameId"
              select
              label="Jeu"
              margin="normal"
              value={formik.values.gameId}
              onChange={formik.handleChange}
              error={formik.touched.gameId && Boolean(formik.errors.gameId)}
              helperText={formik.touched.gameId && formik.errors.gameId}
              sx={formStyles}
            >
              {games.map((game) => (
                <MenuItem key={game.id} value={game.id}>
                  {game.title}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              id="maxParticipants"
              name="maxParticipants"
              label="Nombre de participants maximum"
              type="number"
              margin="normal"
              value={formik.values.maxParticipants}
              onChange={formik.handleChange}
              error={formik.touched.maxParticipants && Boolean(formik.errors.maxParticipants)}
              helperText={formik.touched.maxParticipants && formik.errors.maxParticipants}
              sx={formStyles}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DateTimePicker
                label="Date de début"
                value={formik.values.startDate ? dayjs(formik.values.startDate) : null}
                onChange={(newValue) => {
                  formik.setFieldValue('startDate', newValue ? newValue.format('YYYY-MM-DDTHH:mm') : '');
                }}
                sx={{
                  ...formStyles,
                  width: '100%',
                  mt: 2,
                  mb: 1,
                  '& .MuiPickersDay-root.Mui-selected': {
                    backgroundColor: 'var(--color-secondary) !important',
                    '&:hover': {
                      backgroundColor: 'var(--color-hover) !important',
                    },
                    '&:focus': {
                      backgroundColor: 'var(--color-secondary) !important',
                    },
                  },
                  '& .MuiPickersDay-today': {
                    borderColor: 'var(--color-secondary) !important',
                    color: 'var(--color-secondary) !important',
                  },
                  '& .MuiButton-root': {
                    color: 'var(--color-secondary) !important',
                  },
                  '& .MuiClock-pin': {
                    backgroundColor: 'var(--color-secondary) !important',
                  },
                  '& .MuiClockPointer-root': {
                    backgroundColor: 'var(--color-secondary) !important',
                  },
                  '& .MuiClockPointer-thumb': {
                    backgroundColor: 'var(--color-secondary) !important',
                    borderColor: 'var(--color-secondary) !important',
                  },
                  '& .MuiClockNumber-root.Mui-selected': {
                    backgroundColor: 'var(--color-secondary) !important',
                  },
                  '& .MuiPickersYear-yearButton.Mui-selected': {
                    backgroundColor: 'var(--color-secondary) !important',
                  },
                  '& .MuiPickersMonth-monthButton.Mui-selected': {
                    backgroundColor: 'var(--color-secondary) !important',
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'var(--color-secondary) !important',
                  },
                  '& .MuiTab-root.Mui-selected': {
                    color: 'var(--color-secondary) !important',
                  },
                }}
                slotProps={{
                  textField: {
                    error: formik.touched.startDate && Boolean(formik.errors.startDate),
                    helperText: formik.touched.startDate && formik.errors.startDate,
                    focused: false,
                  },
                  actionBar: {
                    actions: ['accept'],
                  },
                }}
                disablePast
                views={['year', 'month', 'day', 'hours', 'minutes']}
              />
            </LocalizationProvider>

            <TextField
              fullWidth
              id="description"
              name="description"
              label="Description"
              multiline
              rows={4}
              margin="normal"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              sx={formStyles}
            />

            <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
              <Button
                type="button"
                sx={{
                  borderColor: 'var(--color-secondary)',
                  color: 'white',
                  '&:hover': {
                    borderColor: 'var(--color-hover)',
                    color: 'var(--color-secondary)',
                    bgcolor: 'transparent',
                  },
                }}
                onClick={() => navigate('/tournaments')}
              >
                Annuler
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: 'var(--color-secondary)',
                  color: 'white',
                  '&:hover': { bgcolor: 'var(--color-hover)' },
                }}
              >
                Créer le tournoi
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CreateTournament;
