import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Download = () => {
  return (
    <Box sx={{ bgcolor: '#202020', minHeight: '100vh' }}>
      <Container maxWidth="xl">
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4" fontWeight="bold" sx={{ mb: 0.25 }}>
            Téléchargements
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Téléchargez les fichiers nécessaires pour les tournois
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Download;
