import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { CountdownProps } from './types';

export const Countdown: React.FC<CountdownProps> = ({ startDate }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const tournamentStart = new Date(startDate);
      const diff = tournamentStart.getTime() - now.getTime();
      
      if (diff < 0) {
        setTimeLeft('Tournoi commencé');
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      
      if (days > 0) {
        setTimeLeft(`${days}j ${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`);
      } else if (hours > 0) {
        setTimeLeft(`${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`);
      } else if (minutes > 0) {
        setTimeLeft(`${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`);
      } else {
        setTimeLeft(`${seconds.toString().padStart(2, '0')}s`);
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [startDate]);

  return (
    <>
      <Typography 
        variant="body2" 
        sx={{ 
          color: '#aaa',
          mb: 1,
          fontSize: '0.9rem',
          fontWeight: 500
        }}
      >
        Début du tournoi dans :
      </Typography>
      <Box sx={{ 
        mb: 2.5,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        color: '#fff',
        background: 'rgba(0, 0, 0, 0.2)',
        padding: '8px 12px',
        borderRadius: '8px',
        border: '1px solid rgba(255, 255, 255, 0.1)'
      }}>
        <AccessTimeIcon sx={{ fontSize: 18, color: '#aaa' }} />
        <Typography variant="body2" sx={{ 
          fontFamily: 'monospace',
          letterSpacing: '0.5px'
        }}>
          {timeLeft}
        </Typography>
      </Box>
    </>
  );
};
