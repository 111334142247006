import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Toolbar,
  Box,
  useTheme,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from '@mui/icons-material/Logout';
import { NavItem } from '../../types/navigation';

interface SidebarProps {
  open: boolean;
  navItems: NavItem[];
  user: any;
  onDrawerToggle: () => void;
  onLogout: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  open,
  navItems,
  user,
  onDrawerToggle,
  onLogout,
}) => {
  const location = useLocation();
  const muiTheme = useTheme();

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? 240 : 65,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? 240 : 65,
          boxSizing: 'border-box',
          background: 'rgba(32, 32, 32, 0.95)',
          backdropFilter: 'blur(8px)',
          borderRight: '1px solid rgba(255, 255, 255, 0.1)',
          overflowX: 'hidden',
          transition: muiTheme.transitions.create(['width'], {
            easing: muiTheme.transitions.easing.sharp,
            duration: muiTheme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Toolbar
      sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: open ? 'flex-end' : 'center',
          px: [1],
          height: 70,
        }}
      >
        <IconButton onClick={onDrawerToggle} sx={{ color: 'white' }}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      <List>
        {navItems
          .filter(item => !item.requiresAuth || (item.requiresAuth && user))
          .map((item) => (
            <ListItem key={item.name} disablePadding sx={{ marginBottom: '8px' }}>
              <ListItemButton
                component={RouterLink}
                to={item.path}
                selected={location.pathname === item.path}
                sx={{
                  borderRadius: '8px',
                  mx: 1,
                  color: location.pathname === item.path ? 'primary.main' : 'text.primary',
                  '&.Mui-selected': {
                    bgcolor: 'rgba(255, 107, 43, 0.1)',
                    '&:hover': {
                      color: 'primary.main',
                      bgcolor: 'rgba(255, 107, 43, 0.2)',
                    },
                  },
                  '&:hover': {
                    bgcolor: 'rgba(255, 107, 43, 0.05)',
                  },
                }}
              >
                <ListItemIcon sx={{ 
                  color: location.pathname === item.path ? 'primary.main' : 'text.primary',
                  minWidth: '38',
                  ml: -0.38
                }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      {user && (
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={onLogout}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'rgba(255, 255, 255, 0.7)',
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary="Déconnexion"
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiListItemText-primary': {
                      color: 'rgba(255, 255, 255, 0.7)',
                    },
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
        </List>
      )}
    </Drawer>
  );
};

export default Sidebar;
