import api from '../config/api';
import { AxiosResponse } from 'axios';

interface User {
  id: number;
  name: string;
  email: string;
  role: string;
}

export const getAllUsers = async (): Promise<User[]> => {
  try {
    const response: AxiosResponse<User[]> = await api.get('/users');
    return response.data;
  } catch (error) {
    throw error;
  }
}; 