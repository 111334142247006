import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import * as api from '../../services/api';
import GameList from '../Game';
import { Game } from '../../types';

const GameManagement = () => {
    const [games, setGames] = useState<Game[]>([]);
    const { isAuthenticated } = useAuth();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await api.getAllGames();
                setGames(response.data);  
            } catch (error) {
                console.error('Erreur lors de la récupération des jeux:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchGames();
    }, []);
    
    const handleCreateGame = () => {
        navigate('/games/create');
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ bgcolor: '#202020', minHeight: '100vh', py: 4 }}>
            <Container maxWidth="xl">
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                                <Box>
                                    <Typography variant="h4" fontWeight="bold" sx={{ mb: 0.25 }}>
                                        Jeux
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        Tous les jeux disponibles
                                    </Typography>
                                </Box>
                                {isAuthenticated && (
                                    <Button
                                        variant="contained"
                                        onClick={handleCreateGame}
                                        sx={{
                                            bgcolor: 'var(--color-secondary)',
                                            color: 'white',
                                            '&:hover': { bgcolor: 'var(--color-hover)' },
                                        }}
                                    >
                                        Créer un jeu
                                    </Button>
                                )}
                            </Box>
                            <GameList games={games} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default GameManagement;