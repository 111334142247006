import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Avatar
} from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Game } from '../../types';

interface GameListProps {
  games: Game[];
}

export const GameList: React.FC<GameListProps> = ({ games }) => {
  const [view, setView] = useState<'list' | 'grid'>('grid');

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: 'list' | 'grid',
  ) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };

  const getGameImage = (game: Game): string => {
    if (!game.image) {
      return '/images/games/default.jpg';
    }
    return `data:image/jpeg;base64,${game.image}`;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="view mode"
        >
          <ToggleButton value="list" aria-label="list view">
            <ViewListIcon />
          </ToggleButton>
          <ToggleButton value="grid" aria-label="grid view">
            <ViewModuleIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {view === 'list' ? (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {games.map((game) => (
            <ListItem
              key={game.id}
              sx={{
                mb: 2,
                borderRadius: 1,
                bgcolor: 'background.paper',
                '&:hover': { bgcolor: 'action.hover' }
              }}
            >
              <ListItemAvatar>
                <Avatar
                  variant="rounded"
                  src={getGameImage(game)}
                  sx={{ width: 60, height: 60, borderRadius: 1 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" component="div">
                    {game.title}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Grid container spacing={3}>
          {games.map((game) => (
            <Grid item xs={12} sm={6} md={4} key={game.id}>
              <Card sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': { transform: 'translateY(-4px)', transition: 'transform 0.2s' }
              }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={getGameImage(game)}
                  alt={game.title}
                  sx={{ objectFit: 'cover' }}
                />
                <CardContent>
                  <Typography variant="h6" component="div">
                    {game.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default GameList; 