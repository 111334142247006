import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useSnackbar } from '../../contexts/SnackbarContext';
import * as api from '../../services/api';
import { Tournament } from '../../types';
import { FilterBar } from './FilterBar';
import { TournamentCard } from './TournamentCard';
import { FilterStatus, FilterFormat } from './types';

const TournamentList = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<FilterStatus>('all');
  const [formatFilter, setFormatFilter] = useState<FilterFormat>('all');

  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    tournamentId: string | null;
    tournamentName: string;
  }>({
    open: false,
    tournamentId: null,
    tournamentName: '',
  });

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const response = await api.getTournaments();
        setTournaments(response.data);
      } catch (err: any) {
        setError(err.response?.data?.error || 'Failed to fetch tournaments');
      } finally {
        setLoading(false);
      }
    };

    fetchTournaments();
  }, []);

  const handleCreateTournament = () => {
    navigate('/tournaments/create');
  };

  const handleJoinTournament = async (tournamentId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      await api.joinTournament(tournamentId);
      enqueueSnackbar('Vous êtes inscrit au tournoi', { variant: 'success' });
      const response = await api.getTournaments();
      setTournaments(response.data);
    } catch (err: any) {
      enqueueSnackbar(err.response?.data?.error || 'Impossible de rejoindre le tournoi', { variant: 'error' });
    }
  };

  const handleLeaveTournament = async (tournamentId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      await api.leaveTournament(tournamentId);
      enqueueSnackbar("Vous n'êtes plus inscrit au tournoi", { variant: 'warning' });
      const response = await api.getTournaments();
      setTournaments(response.data);
    } catch (err: any) {
      enqueueSnackbar(err.response?.data?.error || 'Impossible de quitter le tournoi', { variant: 'error' });
    }
  };

  const handleDeleteTournament = async (tournamentId: string, tournamentName: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setDeleteDialog({
      open: true,
      tournamentId,
      tournamentName,
    });
  };

  const handleConfirmDelete = async () => {
    if (!deleteDialog.tournamentId) return;

    try {
      await api.deleteTournament(deleteDialog.tournamentId);
      enqueueSnackbar('Le tournoi a bien été supprimé', { variant: 'success' });
      setTournaments(prev => prev.filter(t => t.id !== deleteDialog.tournamentId));
    } catch (err: any) {
      enqueueSnackbar(err.response?.data?.error || 'Impossible de supprimer le tournoi', { variant: 'error' });
    } finally {
      setDeleteDialog({ open: false, tournamentId: null, tournamentName: '' });
    }
  };

  const filterTournaments = (tournament: Tournament) => {
    const matchesStatus = statusFilter === 'all' || tournament?.status === statusFilter;
    const matchesFormat = formatFilter === 'all' || tournament?.format === formatFilter;
    const matchesSearch = !searchQuery ||
      tournament.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (tournament.description && tournament.description.toLowerCase().includes(searchQuery.toLowerCase()));

    return matchesStatus && matchesFormat && matchesSearch;
  };
  

  const filteredTournaments = tournaments.filter(filterTournaments);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: '#202020', minHeight: '100vh' }}>
      <Container maxWidth="xl">
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography variant="h4" fontWeight="bold" sx={{ mb: 0.25 }}>
                    Tournois
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Tous les tournois disponibles
                  </Typography>
                </Box>
                {isAuthenticated && (
                  <Button
                    variant="contained"
                    onClick={handleCreateTournament}
                    sx={{
                      bgcolor: 'var(--color-secondary)',
                      color: 'white',
                      '&:hover': { bgcolor: 'var(--color-hover)' },
                    }}
                  >
                    Créer un tournoi
                  </Button>
                )}
              </Box>

              {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {error}
                </Alert>
              )}

              <FilterBar
                searchQuery={searchQuery}
                onSearchChange={setSearchQuery}
                statusFilter={statusFilter}
                onStatusChange={setStatusFilter}
                formatFilter={formatFilter}
                onFormatChange={setFormatFilter}
              />

              <Grid container spacing={3} sx={{ mt: 2 }}>
                {filteredTournaments.map((tournament) => (
                  <Grid item xs={12} sm={6} md={4} key={tournament.id}>
                    <TournamentCard
                      tournament={tournament}
                      onJoin={handleJoinTournament}
                      onLeave={handleLeaveTournament}
                      onDelete={handleDeleteTournament}
                      isAuthenticated={isAuthenticated}
                      userId={user?.id}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, tournamentId: null, tournamentName: '' })}
      >
        <DialogTitle>Confirmer la suppression du tournoi</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer le tournoi "{deleteDialog.tournamentName}" ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialog({ open: false, tournamentId: null, tournamentName: '' })}
            sx={{ color: 'text.secondary' }}
          >
            Annuler
          </Button>
          <Button 
            onClick={handleConfirmDelete}
            sx={{ 
              color: 'error.main',
              '&:hover': {
                bgcolor: 'error.dark',
                color: 'white',
              }
            }}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TournamentList;
