import React from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Alert,
  Paper,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useSnackbar } from '../../contexts/SnackbarContext';

const validationSchema = yup.object({
  username: yup
    .string()
    .min(3, 'Le nom d\'utilisateur doit contenir au moins 3 caractères')
    .required('Le nom d\'utilisateur est requis'),
  email: yup
    .string()
    .email('Entrez une adresse email valide')
    .required('L\'email est requis'),
  password: yup
    .string()
    .min(6, 'Le mot de passe doit contenir au moins 6 caractères')
    .required('Le mot de passe est requis'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Les mots de passe doivent correspondre')
    .required('La confirmation du mot de passe est requise'),
});

const Register = () => {
  const { register } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = React.useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await register(values.username, values.email, values.password);
        enqueueSnackbar('Inscription réussie ! Vous pouvez maintenant vous connecter.', { variant: 'success' });
        navigate('/login');
      } catch (err: any) {
        setError(err.response?.data?.error || 'L\'inscription a échoué');
        enqueueSnackbar('Une erreur est survenue lors de l\'inscription.', { variant: 'error' });
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Inscription
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Nom d'utilisateur"
            name="username"
            autoComplete="username"
            autoFocus
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#808080',
                },
                '&:hover fieldset': {
                  borderColor: '#A0A0A0',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#A0A0A0',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#808080',
                '&.Mui-focused': {
                  color: '#A0A0A0',
                },
              },
              '& .MuiOutlinedInput-input': {
                '&:focus': {
                  boxShadow: 'none',
                },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresse email"
            name="email"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#808080',
                },
                '&:hover fieldset': {
                  borderColor: '#A0A0A0',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#A0A0A0',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#808080',
                '&.Mui-focused': {
                  color: '#A0A0A0',
                },
              },
              '& .MuiOutlinedInput-input': {
                '&:focus': {
                  boxShadow: 'none',
                },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="new-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#808080',
                },
                '&:hover fieldset': {
                  borderColor: '#A0A0A0',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#A0A0A0',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#808080',
                '&.Mui-focused': {
                  color: '#A0A0A0',
                },
              },
              '& .MuiOutlinedInput-input': {
                '&:focus': {
                  boxShadow: 'none',
                },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirmer le mot de passe"
            type="password"
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#808080',
                },
                '&:hover fieldset': {
                  borderColor: '#A0A0A0',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#A0A0A0',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#808080',
                '&.Mui-focused': {
                  color: '#A0A0A0',
                },
              },
              '& .MuiOutlinedInput-input': {
                '&:focus': {
                  boxShadow: 'none',
                },
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: 'var(--color-secondary)',
              color: 'white',
              padding: '1rem 2.5rem',
              fontSize: '1.1rem',
              fontWeight: 500,
              borderRadius: '4px',
              textTransform: 'none',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: 'var(--color-hover)',
              },
            }}
          >
            S'inscrire
          </Button>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Link
              component={RouterLink}
              to="/login"
              sx={{
                color: '#E0E0E0',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  color: '#A0A0A0',
                },
              }}
            >
              Vous avez déjà un compte ? Connectez-vous
            </Link>
          </Box>
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'center',
            mt: 4,
          }}>
            <Box sx={{ 
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              padding: '8px 16px',
              borderRadius: '20px',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                transform: 'translateX(-5px)',
              }
            }}>
              <Link
                component={RouterLink}
                to="/"
                sx={{
                  color: '#E0E0E0',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  fontSize: '0.95rem',
                  fontWeight: 500,
                  '&:hover': {
                    color: '#FFFFFF',
                  },
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ 
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <path d="M19 12H5M12 19l-7-7 7-7" />
                </svg>
                Retour
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
