import React, { createContext, useContext, useState, useEffect } from 'react';
import { AuthState, User } from '../types';
import * as api from '../services/api';

interface AuthContextType extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  register: (username: string, email: string, password: string) => Promise<void>;
  logout: () => void;
}

const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  loading: true,
  error: null
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<AuthState>(initialState);

  useEffect(() => {
    const initAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setState((prev: AuthState) => ({ ...prev, loading: false }));
        return;
      }

      try {
        const response = await api.verifyToken();
        setState((prev: AuthState) => ({
          ...prev,
          user: response.data,
          isAuthenticated: true,
          token,
          loading: false,
          error: null
        }));
      } catch (error) {
        localStorage.removeItem('token');
        setState((prev: AuthState) => ({
          ...prev,
          user: null,
          token: null,
          isAuthenticated: false,
          loading: false,
          error: 'Session expired'
        }));
      }
    };

    initAuth();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await api.login(email, password);
      const { token, user } = response.data;
      localStorage.setItem('token', token);
      setState((prev: AuthState) => ({
        ...prev,
        user,
        token,
        isAuthenticated: true,
        loading: false,
        error: null
      }));
    } catch (error: any) {
      setState((prev: AuthState) => ({
        ...prev,
        error: error.response?.data?.error || 'Login failed',
      }));
    }
  };

  const register = async (username: string, email: string, password: string) => {
    try {
      const response = await api.register(username, email, password);
      const { token, user } = response.data;
      localStorage.setItem('token', token);
      setState((prev: AuthState) => ({
        ...prev,
        user,
        token,
        isAuthenticated: true,
        loading: false,
        error: null
      }));
    } catch (error: any) {
      setState((prev: AuthState) => ({
        ...prev,
        error: error.response?.data?.error || 'Registration failed',
      }));
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setState((prev: AuthState) => ({
      ...prev,
      user: null,
      token: null,
      isAuthenticated: false,
      error: null
    }));
  };

  return (
    <AuthContext.Provider value={{ ...state, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
