import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Stack,
  Button,
  styled,
} from '@mui/material';
import { MoreVert, ArrowForward } from '@mui/icons-material';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export interface TodayTournament {
  time: string;
  title: string;
  participants: number;
  status: string;
}

export interface TodayTournamentsCardProps {
  tournaments: readonly TodayTournament[];
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#252525',
  borderRadius: 16,
  padding: theme.spacing(3),
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: 'linear-gradient(90deg, #FF6B6B, #FF8E53)',
  },
}));

export const TodayTournamentsCard: React.FC<TodayTournamentsCardProps> = ({ tournaments }) => {
  return (
    <StyledPaper elevation={0}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5" fontWeight="bold">
            Aujourd'hui
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            {dayjs().locale('fr').format('dddd D MMMM')}
          </Typography>
        </Box>
        <IconButton size="small">
          <MoreVert />
        </IconButton>
      </Box>

      <Box sx={{ position: 'relative', mt: 2 }}>
        {/* Timeline line */}
        <Box
          sx={{
            position: 'absolute',
            left: '58px',
            top: '0',
            height: 'calc(100% - 68px)', // Réduire la hauteur pour s'arrêter avant le bouton
            width: '2px',
            bgcolor: 'rgba(182, 182, 182, 0.1)',
          }}
        />
        
        {/* Tournament timeline items */}
        <Stack spacing={2}>
          {tournaments.map((tournament, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
              }}
            >
              {/* Time dot */}
              <Box sx={{ position: 'relative', minWidth: '60px' }}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    textAlign: 'right',
                    pr: 2.5,
                    pt: 4.5,
                  }}
                >
                  {tournament.time}
                </Typography>
                <Box
                  sx={{
                    position: 'absolute',
                    right: '-5px',
                    top: '40px',
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    bgcolor: tournament.status === 'En cours' ? '#4ECDC4' : 
                            tournament.status === 'En attente' ? '#FF6B6B' : '#666',
                    border: '2px solid text.secondary',
                  }}
                />
              </Box>

              {/* Tournament card */}
              <Box
                sx={{
                  flex: 1,
                  p: 2,
                  borderRadius: 1.5,
                  bgcolor: '#303030'
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 0.5}}>
                  <Typography variant="body1" fontWeight="medium">
                    {tournament.title}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      bgcolor: tournament.status === 'En cours' ? 'rgba(78, 205, 196, 0.1)' : 
                              tournament.status === 'En attente' ? 'rgba(255, 107, 107, 0.1)' : 
                              'rgba(255, 255, 255, 0.1)',
                      color: tournament.status === 'En cours' ? '#4ECDC4' : 
                            tournament.status === 'En attente' ? '#FF6B6B' : 
                            'rgba(255, 255, 255, 0.7)',
                    }}
                  >
                    {tournament.status}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {tournament.participants} participants
                </Typography>
              </Box>
            </Box>
          ))}
        </Stack>

        {/* View All Button */}
        <Box sx={{ mt: 3 }}>
          <Button
            endIcon={<ArrowForward />}
            fullWidth
            sx={{
              color: '#FF6B6B',
              py: 1,
              borderColor: 'rgba(255, 107, 107, 0.3)',
              '&:hover': {
                borderColor: '#FF6B6B',
                bgcolor: 'rgba(255, 107, 107, 0.1)',
              },
            }}
            variant="outlined"
          >
            Voir tout
          </Button>
        </Box>
      </Box>
    </StyledPaper>
  );
};

export default TodayTournamentsCard;
