import React from 'react';
import './LandingPage.css';
import Navbar from '../components/LandingPage/Navbar';
import Hero from '../components/LandingPage/Hero';
import Features from '../components/LandingPage/Features';
import CallToAction from '../components/LandingPage/CallToAction';
import Footer from '../components/LandingPage/Footer';
import Separator from '../components/LandingPage/Separator';
import Location from '../components/LandingPage/Location';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Navbar />
      <Hero />
      <Separator />
      <Features />
      <Separator />
      <Location />
      <Separator />
      <CallToAction />
      <Separator />
      <Footer />
    </div>
  );
};

export default LandingPage;
