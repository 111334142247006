import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Correction pour l'icône du marqueur
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Composant pour gérer les événements de la carte
function MapEvents() {
  const map = useMap();

  React.useEffect(() => {
    const enableScrollZoom = () => {
      map.scrollWheelZoom.enable();
    };

    const disableScrollZoom = () => {
      map.scrollWheelZoom.disable();
    };

    map.on('click', enableScrollZoom);
    map.on('mouseout', disableScrollZoom);

    return () => {
      map.off('click', enableScrollZoom);
      map.off('mouseout', disableScrollZoom);
    };
  }, [map]);

  return null;
}

const Location = () => {
  const position = [47.355368, 0.692619];

  return (
    <Box
      sx={{
        bgcolor: '#1A1A1A',
        color: 'white',
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          sx={{
            textAlign: 'center',
            mb: 4,
            fontFamily: 'Inter, sans-serif',
            fontSize: { xs: '2rem', md: '2.5rem' },
            fontWeight: 600,
          }}
        >
          Où nous trouver ?
        </Typography>
        
        <Typography
          sx={{
            textAlign: 'center',
            mb: 4,
            fontFamily: 'Inter, sans-serif',
            color: '#9E9E9E',
            fontSize: { xs: '1rem', md: '1.2rem' },
          }}
        >
          Salle de l'Alouette à Joué-lès-Tours
        </Typography>

        <Box
          sx={{
            width: '100%',
            height: '400px',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
            '& .leaflet-container': {
              width: '100%',
              height: '100%',
              borderRadius: '12px',
              background: '#1A1A1A',
            },
          }}
        >
          <MapContainer 
            center={position} 
            zoom={16} 
            scrollWheelZoom={false}
            style={{ width: '100%', height: '100%' }}
          >
            <MapEvents />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>
                Salle de l'Alouette
              </Popup>
            </Marker>
          </MapContainer>
        </Box>
      </Container>
    </Box>
  );
};

export default Location;
