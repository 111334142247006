import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Stack,
  styled,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';

export interface TopPlayer {
  rank: string;
  name: string;
  game: string;
  points: string;
  winRate: string;
}

interface TopPlayersCardProps {
  players: readonly TopPlayer[];
  currentYear: number;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#252525',
  borderRadius: 16,
  padding: theme.spacing(3),
  height: '896px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: 'linear-gradient(90deg, #FF6B6B, #FF8E53)',
  },
}));

export const TopPlayersCard: React.FC<TopPlayersCardProps> = ({ players, currentYear }) => {
  return (
    <StyledPaper elevation={0}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box>
          <Typography variant="h5" fontWeight="bold">
            Joueurs de {currentYear}
          </Typography>
        </Box>
        <IconButton>
          <MoreVert />
        </IconButton>
      </Box>

      <Stack spacing={2} sx={{ 
        mb: 3, 
        flex: 1, 
        overflowY: 'auto',
        height: '600px',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(255,255,255,0.05)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(255,255,255,0.1)',
          borderRadius: '4px',
          '&:hover': {
            background: 'rgba(255,255,255,0.15)',
          },
        },
      }}>
        {players.map((player, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              p: 2.5,
              minHeight: '72px',
              borderRadius: 2,
              bgcolor: '#303030'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
              <Typography
                variant="caption"
                sx={{
                  px: 1.5,
                  py: 0.5,
                  borderRadius: 1,
                  bgcolor: 'rgba(255, 107, 107, 0.1)',
                  color: '#FF6B6B',
                  fontWeight: 'bold'
                }}
              >
                {player.rank}
              </Typography>
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {player.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {player.game} • {player.points} pts
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" sx={{ color: '#4ECDC4' }}>
                {player.winRate}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
    </StyledPaper>
  );
};

export default TopPlayersCard;
