import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import Layout from './components/Layout/Layout';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import TournamentList from './components/Tournament/TournamentList';
import CreateTournament from './components/Tournament/CreateTournament';
import CreateGame from './components/Game/CreateGame';
import ProtectedRoute from './components/ProtectedRoute';
import LandingPage from './pages/LandingPage';
import Dashboard from './components/Dashboard/Dashboard';
import Settings from './components/Settings/Settings';
import Chat from './components/Chat/Chat';
import Download from './components/Download/Download';
import Admin from './components/Admin/Admin';

const App = () => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <AuthProvider>
          <SnackbarProvider>
            <Router>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route 
                  path="/app" 
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <TournamentList />
                      </Layout>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/tournaments" 
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <TournamentList />
                      </Layout>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/tournaments/create" 
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <CreateTournament />
                      </Layout>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/games/create" 
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <CreateGame />
                      </Layout>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/dashboard" 
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Dashboard />
                      </Layout>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/settings" 
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Settings />
                      </Layout>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/chat" 
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Chat />
                      </Layout>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/download" 
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Download />
                      </Layout>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/admin" 
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Admin />
                      </Layout>
                    </ProtectedRoute>
                  } 
                />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            </Router>
          </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
