import React from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from '@mui/material';
import { FilterBarProps } from './types';

// Ajoutez les objets de traduction
const statusTranslations = {
  'all': 'Tous',
  'open': 'Ouvert',
  'in_progress': 'En cours',
  'completed': 'Terminé'
};

const formatTranslations = {
  'all': 'Tous',
  'single-elimination': 'Simple élimination',
  'double-elimination': 'Double élimination'
};

export const FilterBar: React.FC<FilterBarProps> = ({
  searchQuery,
  onSearchChange,
  statusFilter,
  onStatusChange,
  formatFilter,
  onFormatChange,
}) => {
  const selectStyles = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#808080',
      },
      '&:hover fieldset': {
        borderColor: '#A0A0A0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#A0A0A0',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#808080',
      '&.Mui-focused': {
        color: '#A0A0A0',
      },
    },
    '& .MuiOutlinedInput-input': {
      color: '#fff',
    },
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  };

  const searchFieldStyles = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#808080',
      },
      '&:hover fieldset': {
        borderColor: '#A0A0A0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#A0A0A0',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#808080',
      '&.Mui-focused': {
        color: '#A0A0A0',
      },
    },
    '& .MuiOutlinedInput-input': {
      color: '#fff',
      '&:focus': {
        boxShadow: 'none',
      },
    },
    '& .MuiIconButton-root': {
      color: '#fff',
    },
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  };

  const menuProps = {
    PaperProps: {
      sx: {
        backgroundColor: '#1e1e1e',
        '& .MuiMenuItem-root': {
          color: '#FFFFFF',
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#333333',
          },
          '&:hover': {
            backgroundColor: '#333333',
          },
        },
      },
    },
  };

  return (
    <Paper sx={{
      p: 3,
      mb: 3,
      bgcolor: 'rgba(32, 32, 32, 0.95)',
      backdropFilter: 'blur(8px)',
      borderRadius: 2,
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Rechercher un tournoi"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            sx={searchFieldStyles}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            fullWidth
            margin="normal"
            sx={selectStyles}
          >
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={statusFilter}
              label="Status"
              onChange={(e) => onStatusChange(e.target.value as any)}
              MenuProps={menuProps}
              defaultValue="all"
            >
              {Object.entries(statusTranslations).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            fullWidth
            margin="normal"
            sx={selectStyles}
          >
            <InputLabel id="format-label">Format</InputLabel>
            <Select
              labelId="format-label"
              id="format"
              value={formatFilter}
              label="Format"
              onChange={(e) => onFormatChange(e.target.value as any)}
              MenuProps={menuProps}
              defaultValue="all"
            >
              {Object.entries(formatTranslations).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};
