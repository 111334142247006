import React, { useState, useEffect, useRef } from 'react';
import { Box, List, ListItem, ListItemText, ListItemIcon, IconButton, Typography, Paper, Divider, Button } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import HeadsetIcon from '@mui/icons-material/Headset';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../../contexts/AuthContext';
import { io, Socket } from 'socket.io-client';
import SimplePeer from 'simple-peer';

interface User {
  id: string;
  username: string;
  isSpeaking: boolean;
  isMuted: boolean;
  socketId?: string;
}

interface Room {
  id: string;
  name: string;
  users: User[];
}

interface PeerConnection {
  peer: SimplePeer.Instance;
  stream: MediaStream;
}

interface SignalData {
  type: string;
  sdp?: string;
  candidate?: RTCIceCandidate;
}

const VoiceChat: React.FC = () => {
  const { user } = useAuth();
  const [rooms, setRooms] = useState<Room[]>([
    { id: '1', name: 'General', users: [] },
    { id: '2', name: 'Gaming', users: [] },
    { id: '3', name: 'Tournament', users: [] },
  ]);
  const [currentRoom, setCurrentRoom] = useState<Room | null>(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef<Socket | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const peersRef = useRef<Map<string, PeerConnection>>(new Map());

  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io(process.env.REACT_APP_API_URL || 'http://localhost:3001');

    // Clean up on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      peersRef.current.forEach(({ peer }) => peer.destroy());
    };
  }, []);

  useEffect(() => {
    if (!socketRef.current) return;

    // Handle socket events
    socketRef.current.on('voice-user-joined', ({ users }: { users: User[] }) => {
      if (currentRoom) {
        currentRoom.users = users;
        setRooms(prev => prev.map(room => 
          room.id === currentRoom.id ? { ...room, users } : room
        ));
      }
    });

    socketRef.current.on('voice-user-left', ({ users }: { users: User[] }) => {
      if (currentRoom) {
        currentRoom.users = users;
        setRooms(prev => prev.map(room => 
          room.id === currentRoom.id ? { ...room, users } : room
        ));
      }
    });

    socketRef.current.on('voice-user-updated', ({ users }: { users: User[] }) => {
      if (currentRoom) {
        currentRoom.users = users;
        setRooms(prev => prev.map(room => 
          room.id === currentRoom.id ? { ...room, users } : room
        ));
      }
    });

    socketRef.current.on('voice-signal', ({ userId, signal }: { userId: string; signal: SignalData }) => {
      handlePeerSignal(userId, signal);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.off('voice-user-joined');
        socketRef.current.off('voice-user-left');
        socketRef.current.off('voice-user-updated');
        socketRef.current.off('voice-signal');
      }
    };
  }, [currentRoom]);

  const createPeer = (targetId: string, stream: MediaStream, initiator: boolean): SimplePeer.Instance => {
    const peer = new SimplePeer({
      initiator,
      stream,
      trickle: false,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:global.stun.twilio.com:3478' }
        ]
      }
    });

    peer.on('signal', (signal: SignalData) => {
      if (socketRef.current && currentRoom) {
        socketRef.current.emit('voice-signal', {
          roomId: currentRoom.id,
          userId: targetId,
          signal,
        });
      }
    });

    peer.on('stream', (remoteStream: MediaStream) => {
      const audio = new Audio();
      audio.srcObject = remoteStream;
      audio.play().catch(console.error);
    });

    return peer;
  };

  const handlePeerSignal = (userId: string, signal: SignalData) => {
    const peerConnection = peersRef.current.get(userId);
    if (peerConnection) {
      peerConnection.peer.signal(signal);
    } else if (streamRef.current) {
      const peer = createPeer(userId, streamRef.current, false);
      peer.signal(signal);
      peersRef.current.set(userId, { peer, stream: streamRef.current });
    }
  };

  const handleJoinRoom = async (room: Room) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      // Join room via socket
      if (socketRef.current && user) {
        socketRef.current.emit('voice-join-room', {
          roomId: room.id,
          userId: user.id,
          username: user.username,
        });

        // Create peer connections with existing users
        room.users.forEach(existingUser => {
          if (existingUser.id !== user.id && streamRef.current) {
            const peer = createPeer(existingUser.id, streamRef.current, true);
            peersRef.current.set(existingUser.id, { peer, stream: streamRef.current });
          }
        });

        setCurrentRoom(room);
        setIsConnected(true);
        setIsMuted(false);
      }
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const handleLeaveRoom = () => {
    if (socketRef.current && currentRoom) {
      socketRef.current.emit('voice-leave-room', { roomId: currentRoom.id });
    }
    
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    
    peersRef.current.forEach(({ peer }) => peer.destroy());
    peersRef.current.clear();
    
    setCurrentRoom(null);
    setIsConnected(false);
    setIsMuted(true);
  };

  const toggleMute = () => {
    if (streamRef.current && socketRef.current && currentRoom) {
      streamRef.current.getAudioTracks().forEach(track => {
        track.enabled = isMuted;
      });
      setIsMuted(!isMuted);
      socketRef.current.emit('voice-toggle-mute', {
        roomId: currentRoom.id,
        isMuted: !isMuted,
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
      {/* Rooms List */}
      <Paper sx={{ width: 240, bgcolor: 'background.paper', borderRadius: 2 }}>
        <Typography variant="h6" sx={{ p: 2, fontWeight: 'bold' }}>
          Voice Channels
        </Typography>
        <Divider />
        <List>
          {rooms.map((room) => (
            <ListItem
              key={room.id}
              sx={{
                cursor: 'pointer',
                '&:hover': { bgcolor: 'action.hover' },
                bgcolor: currentRoom?.id === room.id ? 'action.selected' : 'inherit',
              }}
              onClick={() => !isConnected && handleJoinRoom(room)}
              secondaryAction={
                currentRoom?.id === room.id && (
                  <IconButton edge="end" onClick={handleLeaveRoom} size="small">
                    <ExitToAppIcon />
                  </IconButton>
                )
              }
            >
              <ListItemIcon>
                <HeadsetIcon />
              </ListItemIcon>
              <ListItemText 
                primary={room.name}
                secondary={`${room.users.length} users`}
              />
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Current Room View */}
      {currentRoom && (
        <Paper sx={{ flex: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              {currentRoom.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              <Button
                variant="contained"
                color={isMuted ? 'error' : 'primary'}
                startIcon={isMuted ? <MicOffIcon /> : <MicIcon />}
                onClick={toggleMute}
              >
                {isMuted ? 'Unmute' : 'Mute'}
              </Button>
            </Box>
            <Divider sx={{ my: 2 }} />
            <List>
              {[
                { id: user?.id || '1', username: user?.username || 'You', isSpeaking: !isMuted, isMuted },
                ...currentRoom.users.filter(u => u.id !== user?.id)
              ].map((user) => (
                <ListItem key={user.id}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary={user.username}
                    secondary={
                      <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {user.isMuted ? <MicOffIcon fontSize="small" /> : <MicIcon fontSize="small" />}
                        {user.isSpeaking && !user.isMuted && 'Speaking...'}
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default VoiceChat; 