import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  LinearProgress,
  Paper,
  styled,
  AvatarGroup,
  Avatar,
  Skeleton,
  Button,
} from '@mui/material';
import { MoreVert, Gamepad, Schedule, Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Tournament } from '../../types';

interface NextTournamentCardProps {
  tournament?: Tournament;
  isLoading: boolean;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#252525',
  borderRadius: 16,
  padding: theme.spacing(3),
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: 'linear-gradient(90deg, #FF6B6B, #FF8E53)',
  },
}));

const TournamentCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#303030',
  borderRadius: 12,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  transition: 'all 0.3s ease',
}));

const formatDateTime = (date: string) => {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const formattedDate = `${day}/${month}/${d.getFullYear()}`;
  const hours = d.getHours().toString().padStart(2, '0');
  const minutes = d.getMinutes().toString().padStart(2, '0');
  return `${formattedDate} - ${hours}:${minutes}`;
};

export const NextTournamentCard: React.FC<NextTournamentCardProps> = ({ tournament, isLoading }) => {
  if (isLoading) {
    return (
      <StyledPaper elevation={0} sx={{ height: '300px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Skeleton variant="text" width={200} height={32} />
          <Skeleton variant="circular" width={32} height={32} />
        </Box>
        <TournamentCard>
          <Skeleton variant="text" width="60%" height={32} />
          <Skeleton variant="text" width="40%" height={24} />
          <Skeleton variant="rectangular" height={60} sx={{ borderRadius: 1 }} />
          <Skeleton variant="text" width="100%" height={24} />
          <Skeleton variant="rectangular" height={32} sx={{ borderRadius: 1 }} />
        </TournamentCard>
      </StyledPaper>
    );
  }

  if (!tournament) {
    return (
      <StyledPaper elevation={0} sx={{ height: '300px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5" fontWeight="bold">
            Prochain Tournoi
          </Typography>
        </Box>
        
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
            height: '190px',
            mt: 3.5,
            width: '100%',
            bgcolor: '#303030',
            borderRadius: '16px',
            padding: '50px',
          }}
        >
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ color: '#C1C1C1' }}
          >
            Aucun tournoi actif pour le moment
          </Typography>

          <Button
            component={Link}
            to="/tournaments/create"
            variant="contained"
            sx={{
              bgcolor: 'var(--color-secondary)',
              borderRadius: '8px',
              color: 'white',
              '&:hover': { bgcolor: 'var(--color-hover)' },
            }}
          >
            Créer un tournoi
          </Button>
        </Box>
      </StyledPaper>
    );
  }

  return (
    <StyledPaper elevation={0} sx={{ height: '300px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" fontWeight="bold">
          Prochain Tournoi
        </Typography>
        <IconButton size="small">
          <MoreVert />
        </IconButton>
      </Box>

      <TournamentCard>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1.5 }}>
          <Box>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
              {tournament.Game?.title || tournament.name}
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Gamepad sx={{ color: '#FF6B6B', fontSize: 18 }} />
                <Typography variant="body2" color="text.secondary">
                  {tournament.format.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Schedule sx={{ color: '#FF6B6B', fontSize: 18 }} />
                <Typography variant="body2" color="text.secondary">
                  {formatDateTime(tournament.startDate)}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Typography
                    variant="caption"
                    sx={{
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      bgcolor: tournament.status === 'open' ? 'rgba(78, 205, 196, 0.1)' : 
                              tournament.status === 'in_progress' ? 'rgba(255, 107, 107, 0.1)' : 
                              'rgba(255, 255, 255, 0.1)',
                      color: tournament.status === 'open' ? '#4ECDC4' : 
                            tournament.status === 'in_progress' ? '#FF6B6B' : 
                            'rgba(255, 255, 255, 0.7)',
                    }}
                  >
                    {tournament.status}
                  </Typography>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.7 }}>
            <Typography variant="body2" color="text.secondary">
              Inscriptions
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {tournament.participants?.length || 0}/{tournament.maxParticipants}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={((tournament.participants?.length || 0) / tournament.maxParticipants) * 100}
            sx={{
              height: 6,
              borderRadius: 2,
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              '& .MuiLinearProgress-bar': {
                background: 'linear-gradient(90deg, #FF6B6B, #FF8E53)',
              },
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }}>
          <AvatarGroup 
            max={14}
            sx={{
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                fontSize: '0.875rem',
                bgcolor: 'rgba(187, 187, 187)'
              },
            }}
          >
            {tournament.participants?.map((participant, index) => (
              <Avatar 
                key={participant.id} 
                src={participant.profilePicture}
                alt={participant.username}
              >
                <span style={{ 
                  marginLeft: index === 0 ? '0' : '4.2px',
                  fontWeight: 'bold',  // Met le texte en gras
                  fontSize: '11.5px'   // Réduit la taille de la police
                }}>
                  {participant.username.charAt(0).toUpperCase()}
                  {participant.username.charAt(1).toUpperCase()}
                </span>
              </Avatar>
            ))}
          </AvatarGroup>
        </Box>
      </TournamentCard>
    </StyledPaper>
  );
};

export default NextTournamentCard;