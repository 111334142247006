import { Box, styled } from '@mui/material';

const StatBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#252525',
  borderRadius: 12,
  padding: theme.spacing(2.5),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  transition: 'transform 0.2s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

export default StatBox; 