import React from 'react';
import { Box, Grid } from '@mui/material';
import StatCard from './StatCard';

const Dashboard = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard 
            title="Utilisateurs"
            value="0"
            description="Nombre total d'utilisateurs"
            icon="👥"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard 
            title="Tournois"
            value="0"
            description="Tournois en cours"
            icon="🏆"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard 
            title="Matchs"
            value="0"
            description="Matchs joués aujourd'hui"
            icon="⚔️"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard 
            title="Jeux"
            value="0"
            description="Nombre de jeux"
            icon="🎮"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard; 