import React from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography,
  Button,
  useTheme as useMuiTheme,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/BubbleChart';
import DownloadIcon from '@mui/icons-material/Download';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../../contexts/AuthContext';
import { alpha } from '@mui/material/styles';
import { NavItem } from '../../types/navigation';
import Sidebar from '../Sidebar/Sidebar';

const navItems: NavItem[] = [
  { name: 'Accueil', path: '/dashboard', icon: <DashboardIcon />, requiresAuth: true },
  { name: 'Tournois', path: '/tournaments', icon: <EmojiEventsIcon />, requiresAuth: true },
  { name: 'Chat', path: '/chat', icon: <ChatIcon />, requiresAuth: true },
  { name: 'Téléchargements', path: '/download', icon: <DownloadIcon />, requiresAuth: true },
  { name: 'Paramètres', path: '/settings', icon: <SettingsIcon />, requiresAuth: true },
  { 
    name: 'Administration', 
    path: '/admin', 
    icon: <AdminIcon />, 
    requiresAuth: true,
    requiresAdmin: true 
  },
];

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { darkMode, toggleDarkMode } = useTheme();
  const { user, logout } = useAuth();
  const muiTheme = useMuiTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(() => {
    try {
      const stored = localStorage.getItem('sidebarState');
      if (stored === null) return false;
      return stored === 'true';
    } catch {
      return false;
    }
  });

  const handleDrawerToggle = () => {
    const newState = !open;
    setOpen(newState);
    localStorage.setItem('sidebarState', String(newState));
  };

  React.useEffect(() => {
    localStorage.setItem('sidebarState', String(open));
  }, [open]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
    navigate('/login');
  };

  const containerVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.4, 0, 0.2, 1]
      }
    },
    exit: { 
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3,
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  const filteredNavItems = navItems.filter(item => {
    if (item.requiresAdmin) {
      return user?.role === 'admin';
    }
    return true;
  });

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: '#202020',
        backgroundAttachment: 'fixed',
        display: 'flex',
      }}
    >
      <Sidebar
        open={open}
        navItems={filteredNavItems}
        user={user}
        onDrawerToggle={handleDrawerToggle}
        onLogout={handleLogout}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1.5,
          width: { sm: `calc(100% - ${open ? 240 : 65}px)` },
          marginLeft: 'auto',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [1],
            mb: 0.5,
            minHeight: 50,
          }}
        >
          <Typography
            variant="h5"
            className="logo-text"
            sx={{
              fontWeight: 700,
              color: '#fff',
              display: 'none', // Cacher le texte
            }}
          >
            TOURS ARENA
          </Typography>
        </Toolbar>
        <AnimatePresence mode="wait">
          <motion.div
            key={location.pathname}
            variants={containerVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default Layout;
