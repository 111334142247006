import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { EmojiEvents, Groups, Visibility } from '@mui/icons-material';
import NextTournamentCard from './NextTournamentCard';
import TournamentGrowthCard from './TournamentGrowthCard';
import TodayTournamentsCard from './TodayTournamentsCard';
import TopPlayersCard from './TopPlayersCard';
import StatBox from './components/StatBox';
import IconWrapper from './components/IconWrapper';
import { Tournament } from '../../types';
import { 
  getActiveTournaments, 
  getStats, 
  getTournamentGrowth, 
  getTodaysTournaments, 
  getTopPlayers 
} from '../../services/api';

interface DashboardStats {
  tournaments: string;
  players: string;
  viewers: string;
}

interface TournamentGrowthData {
  name: string;
  value: number;
}

interface TodayTournament {
  time: string;
  title: string;
  participants: number;
  status: string;
}

interface TopPlayer {
  rank: string;
  name: string;
  game: string;
  points: string;
  winRate: string;
}

const Dashboard = () => {
  const [activeTournaments, setActiveTournaments] = useState<Tournament[]>([]);
  const [stats, setStats] = useState<DashboardStats>({ tournaments: '0', players: '0', viewers: '0' });
  const [growthData, setGrowthData] = useState<TournamentGrowthData[]>([]);
  const [todaysTournaments, setTodaysTournaments] = useState<TodayTournament[]>([]);
  const [topPlayers, setTopPlayers] = useState<TopPlayer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          activeTournamentsRes,
          statsRes,
          growthRes,
          todayRes,
          topPlayersRes
        ] = await Promise.all([
          getActiveTournaments(),
          getStats(),
          getTournamentGrowth(),
          getTodaysTournaments(),
          getTopPlayers()
        ]);

        setActiveTournaments(activeTournamentsRes.data);
        setStats(statsRes.data);
        setGrowthData(growthRes.data);
        setTodaysTournaments(todayRes.data);
        setTopPlayers(topPlayersRes.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Get the next tournament (first active tournament)
  const nextTournament = activeTournaments[0];

  return (
    <Box sx={{ bgcolor: '#202020', minHeight: '100vh' }}>
      <Container maxWidth="xl">
        {/* Header */}
        <Box sx={{ mb: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h4" fontWeight="bold" sx={{ mb: 1 }}>
              Tableau de bord
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Vue d'ensemble de la Tours Arena
            </Typography>
          </Box>
        </Box>

        {/* Stats Grid - First Row */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={4}>
            <StatBox>
              <IconWrapper>
                <EmojiEvents sx={{ color: '#252525', fontSize: 24 }} />
              </IconWrapper>
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  {stats.tournaments}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Tournois Actifs
                </Typography>
              </Box>
            </StatBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <StatBox>
              <IconWrapper>
                <Groups sx={{ color: '#252525', fontSize: 24 }} />
              </IconWrapper>
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  {stats.players}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Joueurs
                </Typography>
              </Box>
            </StatBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <StatBox>
              <IconWrapper>
                <Visibility sx={{ color: '#252525', fontSize: 24 }} />
              </IconWrapper>
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  {stats.viewers}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Spectateurs
                </Typography>
              </Box>
            </StatBox>
          </Grid>
        </Grid>

        {/* Main Content */}
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <NextTournamentCard 
                  tournament={nextTournament} 
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TodayTournamentsCard 
                  tournaments={todaysTournaments}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TournamentGrowthCard 
                  data={growthData}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={4}>
            <TopPlayersCard 
              players={topPlayers}
              currentYear={currentYear}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;
