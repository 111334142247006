import React from 'react';
import './Features.css';
import { FaGamepad, FaTrophy, FaUsers, FaChartBar, FaClock, FaDiscord } from 'react-icons/fa';
import { Box } from '@mui/material';

const Features = () => {
  const features = [
    {
      icon: <FaGamepad />,
      title: 'Multi-Jeux',
      description: 'Supporté pour tous vos jeux favoris'
    },
    {
      icon: <FaTrophy />,
      title: 'Brackets Automatiques',
      description: 'Génération automatique des matchs'
    },
    {
      icon: <FaUsers />,
      title: 'Mode équipe',
      description: 'Créez des équipes pour vos tournois'
    },
    {
      icon: <FaChartBar />,
      title: 'Scores en direct',
      description: 'Suivez les scores en temps réel'
    },
    {
      icon: <FaClock />,
      title: 'Timer intégré',
      description: 'Gérez le temps entre les matchs'
    },
    {
      icon: <FaDiscord />,
      title: 'Chat intégré',
      description: 'Communiquez avec les participants'
    }
  ];

  return (
    <section className="features">
      <h2>Fonctionnalités</h2>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-icon">{feature.icon}</div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
