import React, { createContext, useContext, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

type SnackbarVariant = 'success' | 'error' | 'warning' | 'info';

interface SnackbarMessage {
  message: string;
  variant: SnackbarVariant;
}

interface SnackbarContextType {
  enqueueSnackbar: (message: string, options: { variant: SnackbarVariant }) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<SnackbarMessage | null>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const enqueueSnackbar = (message: string, options: { variant: SnackbarVariant }) => {
    setMessage({ message, variant: options.variant });
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ enqueueSnackbar }}>
      {children}
      {message && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity={message.variant} sx={{ width: '100%' }}>
            {message.message}
          </Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
